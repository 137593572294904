import axios from "axios";

async function updateS3DocumentWithPresignedUrl(
  presignedUrl: string,
  file: File
): Promise<any> {
 try {
  await axios.put(presignedUrl, file, {
    headers: {
      "Content-Type": file.type, // Set the content type of the file
    },
  });
 } catch (error) {
  console.log(error)
 }
}

export {updateS3DocumentWithPresignedUrl}