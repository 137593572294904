import React, { createElement } from "react";
import { ElementFactory } from "survey-core";
import { ReactElementFactory, ReactQuestionFactory } from "survey-react-ui";

import {
  ADD_REMOVE_PANEL,
  AddOrRemovePanelQuestion,
  AddRemovePanelElement,
} from "./AddRemovePanelWidget";
import {
  CONFIRMATION_CHECKBOX,
  ConfirmationCheckboxElement,
  ConfirmationCheckboxQuestion,
} from "./ConfirmationCheckbox";
import FilePreviewComponent from "./FilePreviewComponent";

const registerElements = () => {
  ElementFactory.Instance.registerElement(ADD_REMOVE_PANEL, (name) => {
    return new AddRemovePanelElement(name);
  });

  ElementFactory.Instance.registerElement(CONFIRMATION_CHECKBOX, (name) => {
    return new ConfirmationCheckboxElement(name);
  });

  ReactElementFactory.Instance.registerElement("sv-file-preview", (props) => {
    return <FilePreviewComponent {...props} />;
  });
};

const registerQuestions = () => {
  ReactQuestionFactory.Instance.registerQuestion(ADD_REMOVE_PANEL, (props) => {
    return createElement(AddOrRemovePanelQuestion, props);
  });

  ReactQuestionFactory.Instance.registerQuestion(
    CONFIRMATION_CHECKBOX,
    (props) => {
      return React.createElement(ConfirmationCheckboxQuestion, props);
    }
  );
};

const SurveyJSCustomWidgets = {
  registerElements,
  registerQuestions,
};

export default SurveyJSCustomWidgets;
