import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Markup } from "interweave";
import { useSelector } from "react-redux";

import { IS_BROKER_PORTAL } from "config";
import React from "react";
import { customStylesSelector } from "store/selectors/selectors";
import { useT } from "translation";
import { RootState } from "store/store";

interface SummaryI {
  summaryData: any;
}

const Summary: React.FC<SummaryI> = ({ summaryData }) => {
  const { t } = useT();
  const customStyles = useSelector(customStylesSelector);
  const polling = useSelector((state: RootState) => state.quotes.polling);

  const renderValue = (value) => {
    if (typeof value !== "object") {
      return value;
    }
  };

  const renderRow = (rowData, rowKey, rowIndex) => {
    if (typeof rowData?.[rowKey]?.value !== "object") {
      return (
        <TableRow key={rowData?.[rowKey]?.title + rowIndex}>
          <TableCell
            sx={{
              border: "none",
              whiteSpace: "wrap",
              fontWeight: 500,
              fontSize: "14px",
              color: customStyles.muiTheme.palette.text.secondary,
            }}
          >
            <Markup content={rowData?.[rowKey]?.title} />
            <Typography
              sx={{
                border: "none",
                color: customStyles.muiTheme.palette.text.tertiary,
                whiteSpace: "nowrap",
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              {renderValue(rowData?.[rowKey]?.value)}
            </Typography>
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow key={rowData?.[rowKey]?.title}>
          <TableCell
            colSpan={3}
            sx={{
              padding: "16px 0px",
              border: "none",
              whiteSpace: "wrap",
              fontWeight: 500,
              fontSize: "14px",
              color: customStyles.muiTheme.palette.text.secondary,
            }}
          >
            <Accordion
              defaultExpanded
              sx={{
                borderRadius: "12px !important",
                boxShadow: "none",
                border: "none",
                "&.MuiAccordion-root:before": {
                  backgroundColor: "transparent",
                },
                backgroundColor: customStyles.primaryCard.backgroundColor,
              }}
            >
              <AccordionSummary
                sx={{
                  padding: "0px 16px",
                  margin: "0px",
                  minHeight: "0px",
                  ".MuiAccordionSummary-content": {
                    margin: "0px",
                    color: customStyles.muiTheme.palette.text.secondary,
                  },
                  "& .Mui-expanded": {
                    minHeight: "0px",
                  },
                }}
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      color: customStyles.muiTheme.palette.text.secondary,
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Markup content={rowData?.[rowKey]?.title} />
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: "0px",
                  margin: "0px 16px 16px",
                }}
              >
                {(rowData?.[rowKey]?.value || []).map((rowItem, index) => (
                  <TableContainer
                    key={`table-container-${index}`}
                    sx={{
                      overflowX: "auto",
                      border: "1px solid",
                      marginTop: "8px",
                      borderColor: customStyles.secondaryCard.hover.borderColor,
                      borderRadius: "6px",
                    }}
                  >
                    <Table>
                      <TableBody>
                        {Object.keys(rowItem).map((keyRow, index) =>
                          renderRow(rowItem, keyRow, index)
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ))}
              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow>
      );
    }
  };

  if (!summaryData || polling) {
    return null;
  }

  return (
    <Accordion
      defaultExpanded={!IS_BROKER_PORTAL}
      sx={{
        p: "0px",
        borderRadius: "12px !important",
        boxShadow: "none",
        border: "none",
        "&.MuiAccordion-root:before": {
          backgroundColor: "transparent",
        },
        backgroundColor: customStyles.primaryCard.backgroundColor,
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: customStyles.muiTheme.palette.text.secondary,
            }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            color: customStyles.muiTheme.palette.text.secondary,
          }}
        >
          {t("QUOTE_PAGE_SUMMARY")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer sx={{ overflowX: "auto" }}>
          <Table>
            <TableBody>
              {Object.keys(summaryData).map((dataKey, dataIndex) =>
                renderRow(summaryData, dataKey, dataIndex)
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default Summary;
