import { RootState } from "store/store";

export const portalConfigSelector = (state: RootState) => state.portalConfig;
export const portalFontFamilyNameSelector = (state: RootState) =>
  state.portalConfig?.data?.font?.name;
export const footerNoteSelector = (state: RootState) =>
  state.portalConfig?.data?.otherConfig?.footerNote;
export const logoSelector = (state: RootState) =>
  state.portalConfig?.data?.logo;
export const defaultProductsSelector = (state: RootState) =>
  state.portalConfig.data?.otherConfig?.defaultProducts || [];

export const xApiKeySelector = (state: RootState) =>
  state.portalConfig.data?.otherConfig?.xApiKey || "";

export const initialCurrentStepSelector = (state: RootState) =>
  state.portalConfig.data?.otherConfig?.defaultCurrentStep ?? 0;

export const otherConfigSelector = (state: RootState) =>
  state.portalConfig.data?.otherConfig;

export const restrictedRoutesSelector = (state: RootState) =>
  state.portalConfig.data?.restricted_routes;
