import {
  ArticleOutlined,
  BackupTableOutlined,
  DashboardOutlined,
  GppMaybeOutlined,
  ReportGmailerrorredOutlined,
  RequestQuoteOutlined,
  SupportAgent,
} from "@mui/icons-material";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import StereLogo from "assets/logos/StereLogo";
import { dashboardPageDataAttr } from "constants/dataAttributes";
import { useFlags } from "flagsmith/react";
import { IFlagsmithFeature } from "flagsmith/types";
import { motion } from "framer-motion";
import useIsAdmin from "modules/Agents/useIsAdmin";
import { portalFontFamilyNameSelector, restrictedRoutesSelector } from "portalConfig/selectors";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useT } from "translation";
import { TranslationId } from "translation/types";

const SidebarLink = ({
  link,
  title,
  Icon,
  isCurrentTab,
  isDisabled,
  data_cy,
}: {
  link: string;
  title: string;
  Icon: any;
  isCurrentTab: boolean;
  isDisabled: boolean;
  data_cy?: string;
}) => {
  const { t } = useT();
  const isMobile = useMediaQuery("(max-width:820px)");

  return (
    <Link
      style={{
        textDecoration: "none",
        width: isMobile ? "fit-content" : "100%",
        cursor: isDisabled ? "default" : "pointer",
        pointerEvents: isDisabled ? "none" : "auto",
      }}
      to={isDisabled ? undefined : link}
    >
      <Box
        {...(data_cy ? { "data-cy": data_cy } : {})}
        component={motion.div}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          backgroundColor: isCurrentTab ? "primary.main" : "transparent",
          borderRadius: "4px",
          p: "10px",
          transition: "all 0.3s",
        }}
        whileTap={{ scale: 0.8 }}
        whileHover={{ scale: 1.05 }}
      >
        <Icon
          sx={{
            color: isCurrentTab ? "white" : isDisabled ? "#ACACAC" : "#808080",
          }}
          fontSize="medium"
        />
        {!isMobile && (
          <Typography
            sx={{
              color: isCurrentTab ? "white" : isDisabled ? "#ACACAC" : "grey",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {t(title as TranslationId)}
            {isDisabled ? ` (${t("COMING_SOON")})` : ""}
          </Typography>
        )}
      </Box>
    </Link>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const restrictedRoutes = useSelector(restrictedRoutesSelector);
  const fontFamily = useSelector(portalFontFamilyNameSelector);
  const flags = useFlags(["v1_dashboard"]) as {
    [key: string]: IFlagsmithFeature;
  };
  const isAdmin = useIsAdmin();
  const sidebarLinks = useMemo(() => {
    return [
      {
        title: "DASHBOARD",
        link: "/home/dashboard",
        icon: DashboardOutlined,
        disabled: false,
        feature_flag: "v1_dashboard"
      },
      {
        title: "APPLICATIONS",
        link: "/home/applications",
        icon: ArticleOutlined,
        disabled: false,
      },
      {
        title: "QUOTES",
        link: "/home/quotes",
        icon: RequestQuoteOutlined,
        disabled: false,
      },
      {
        title: "POLICIES",
        link: "/home/policies",
        icon: GppMaybeOutlined,
        disabled: false,
      },
      ...(isAdmin
        ? [
          {
            title: "AGENTS",
            link: "/home/agents",
            icon: SupportAgent,
            data_cy: dashboardPageDataAttr.AGENTS_TAB,
          },
        ]
        : []),
      {
        title: "CLAIMS",
        link: "/home/claims",
        icon: ReportGmailerrorredOutlined,
        disabled: true,
      },
      {
        title: "MY_APPOINTMENTS",
        link: "/home/appointments",
        icon: BackupTableOutlined,
        disabled: false,
      },
    ];
  }, [isAdmin]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "20px",
          gap: "20px",
        }}
      >
        {sidebarLinks
          .filter((link) => {
            if(restrictedRoutes?.length && restrictedRoutes?.includes((link.link.split("/"))?.at(-1))){
              return false
            }
            return link.feature_flag ? flags[link.feature_flag]?.enabled : true;
          })
          .map((sidebarlink) => {
            return (
              <SidebarLink
                key={sidebarlink.title}
                isCurrentTab={location.pathname.includes(sidebarlink.link)}
                link={sidebarlink.link}
                title={sidebarlink.title}
                Icon={sidebarlink.icon}
                isDisabled={sidebarlink.disabled}
                data_cy={sidebarlink.data_cy}
              />
            );
          })}
      </Box>
      <Box
        sx={{ display: "flex", alignItems: "center", mt: "auto", p: "20px" }}
      >
        <Typography
          sx={{
            color: "#020202",
            fontFamily: fontFamily,
            fontSize: "6.875px",
            mr: "5px",
          }}
        >
          Powered By
        </Typography>
        <StereLogo />
      </Box>
    </Box>
  );
};

export default Sidebar;
