import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FunctionFactory,
  Model,
  Serializer,
  settings,
  surveyLocalization,
  UploadFilesEvent,
  ClearFilesEvent
} from "survey-core";
import { Survey } from "survey-react-ui";
import "./SurveyJSCustomWidgets/FilePreviewComponent";

import { createPresignedUrls, deleteDocumentsFromS3, fetchFactoryFunctionsAPI } from "api/siaApplication";
import { COUNTRY } from "config";
import { setLoaderText } from "store/features/loaderSlice";
import { setSubSteps } from "store/features/stepperSlice";
import {
  setApplicantEmail,
  setLoadingValue,
  setScriptRef,
  setSurveyModelFuncs,
} from "store/features/surveyJsSlice";
import { RootState } from "store/store";
import "survey-core/defaultV2.min.css";
import { useT } from "translation";
import { getPrefillData, setPrefillData } from "utils/utils";
import SurveyJSCustomWidgets from "./SurveyJSCustomWidgets";
import applicationUtil, { checkValidData } from "./utils/applicationUtils";

import { useMediaQuery } from "@mui/material";
import "./style.css";
import { updateS3DocumentWithPresignedUrl } from "api/aws";

// Constants
const CLOUDFRONT_URL = 'https://d1riq8a8ohr0oc.cloudfront.net';

SurveyJSCustomWidgets.registerElements();
SurveyJSCustomWidgets.registerQuestions();

Serializer.addProperty("question", { name: "convertToISO:boolean" });
Serializer.addProperty("question", { name: "dependents:string" });

// Note: To enable validations for readOnly questions
settings.readOnly.enableValidation = true;

const SurveyComponent = ({
  surveyJson,
  isKnockoutForm,
}: {
  surveyJson: any;
  isKnockoutForm?: boolean;
}) => {
  const isMobile = useMediaQuery("max-width:425px");
  const [survey, setSurvey] = useState<Model>(null);
  const dispatch = useDispatch();
  const { lang, t } = useT();
  const scriptLoaded = localStorage.getItem("scriptLoaded");
  const scriptCallMade = localStorage.getItem("scriptCallMade");

  // Note - This is added temporarily, in future we need to keep a separate locale for "pt", "en", "es"
  surveyLocalization.locales["en"]["emptyMessage"] =
    lang === "pt" ? "Favor selecionar um valor da lista" : "No data to display";

  const { surveyPageNumber, siaApplication, updatedSiaApplication, clientLatestConfig, scriptRef, knockout } =
    useSelector((state: RootState) => state.surveyJs);
  const { clientAPIKey, applicationConfig } = useSelector(
    (state: RootState) => state.client
  );

  // method that computes the final data to be pre-filled in the survey
  const getSurveyJSONData = (surveyJSON) => {
    // Following change is to fix issue - Not able to see the updated values on knockout
    const appParams = JSON.parse(JSON.stringify(knockout ? updatedSiaApplication?.params : siaApplication?.params));
    if (clientLatestConfig?.touched || clientLatestConfig?.submissionsId) {
      setPrefillData(appParams);
      return appParams;
    } else {
      const clientData = JSON.parse(localStorage.getItem("clientData"));
      let prefillData = getPrefillData();
      if (Object.keys(prefillData).length === 0 && !!clientData) {
        prefillData = checkValidData(surveyJSON, clientData)?.prefill_values;
        setPrefillData(prefillData);
      }
      return prefillData;
    }
  };

  const onValueChanged = (e, f) => {
    // fix to prepopulate email in checkout form
    if (f.name === "applicant_email") {
      dispatch(setApplicantEmail(f.value));
    }
    if (!(f.name ?? "").includes(" ")) {
      setPrefillData({ [f.name]: f.value });
    }
  };

  const onTextMarkdown = (e, f) => {
    const str = f.text;
    f.html = str;
  };

  const attachUSEventListeners = (currentSurvey: Model) => {
    // @ts-expect-error Attaching onUsaDynamicPanelAddRemove for handling location id dropdown
    if (window.onUsaDynamicPanelAddRemove) {
      currentSurvey?.onDynamicPanelAdded.add(
        // @ts-expect-error Attaching onUsaDynamicPanelAddRemove for handling location id dropdown
        window.onUsaDynamicPanelAddRemove
      );
      currentSurvey?.onDynamicPanelRemoved.add(
        // @ts-expect-error Attaching onUsaDynamicPanelAddRemove for handling location id dropdown
        window.onUsaDynamicPanelAddRemove
      );
    }

    // @ts-expect-error
    if (window.onUsaErrorCustomText) {
      // @ts-expect-error
      currentSurvey?.onErrorCustomText.add(window.onUsaErrorCustomText);
    }

    // @ts-expect-error Attaching onUsaDynamicPanelItemValueChanged
    if(window.onUsaDynamicPanelItemValueChanged) {
      currentSurvey?.onDynamicPanelItemValueChanged.add((e, f) => {
        // @ts-expect-error Attaching onUsaDynamicPanelItemValueChanged
        window.onUsaDynamicPanelItemValueChanged(e, f);
      })
      }

    // @ts-expect-error Attaching onUsaValueChanged
    if (window.onUsaValueChanged) {
      currentSurvey?.onValueChanged.remove(onValueChanged);
      currentSurvey?.onValueChanged.add((e, f) => {
        onValueChanged(e, f);
        // @ts-expect-error Attaching onUsaValueChanged
        window.onUsaValueChanged(e, f);
      });
    }

    // @ts-expect-error Attaching onUsaChoicesLazyLoad for loading choices from URL
    if (window.onUsaChoicesLazyLoad) {
      currentSurvey?.onChoicesLazyLoad.add(
        // @ts-expect-error Attaching onUsaChoicesLazyLoad for for loading choices from URL
        window.onUsaChoicesLazyLoad
      );
    }

    // @ts-expect-error Attaching onUsaChoiceDisplayValue for handling choices through URL
    if (window.onUsaChoiceDisplayValue) {
      currentSurvey?.onGetChoiceDisplayValue.add((s, o) => {
        // @ts-expect-error Attaching onUsaChoiceDisplayValue for for handling choices through URL
        window.onUsaChoiceDisplayValue(s, o);
      });
    }
  };

  const attachBREventListeners = (currentSurvey: Model) => {
    // @ts-expect-error Attaching onUsaChoicesLazyLoad for loading choices from URL
    if (window.onUsaChoicesLazyLoad) {
      currentSurvey?.onChoicesLazyLoad.add(
        // @ts-expect-error Attaching onUsaChoicesLazyLoad for for loading choices from URL
        window.onUsaChoicesLazyLoad
      );
    }

    // @ts-expect-error Attaching onUsaChoiceDisplayValue for handling choices through URL
    if (window.onUsaChoiceDisplayValue) {
      currentSurvey?.onGetChoiceDisplayValue.add((s, o) => {
        // @ts-expect-error Attaching onUsaChoiceDisplayValue for for handling choices through URL
        window.onUsaChoiceDisplayValue(s, o);
      });
    }

    // @ts-expect-error Attaching onBrazilValueChanged for handling choices through URL
    if (window.onBrazilValueChanged) {
      currentSurvey?.onDynamicPanelItemValueChanged.add((e, f) => {
        // @ts-expect-error Attaching onBrazilDynamicPanelItemValueChanged
        window.onBrazilDynamicPanelItemValueChanged(e, f);
      })
      currentSurvey?.onValueChanged.remove(onValueChanged);
      currentSurvey?.onValueChanged.add((e, f) => {
        onValueChanged(e, f);
        // @ts-expect-error Attaching onBrazilValueChanged
        window.onBrazilValueChanged(e, f);
        // NOTE: This change allow the question to trigger validation once the focus changed.
        e?.getQuestionByName(f?.name).validate();
      });
    }
  };

  const attachMEXICOEventListeners = (currentSurvey: Model) => {
    // @ts-expect-error Attaching onUsaChoicesLazyLoad for loading choices from URL
    if (window.onUsaChoicesLazyLoad) {
      currentSurvey?.onChoicesLazyLoad.add(
        // @ts-expect-error Attaching onUsaChoicesLazyLoad for for loading choices from URL
        window.onUsaChoicesLazyLoad
      );
    }

    // @ts-expect-error Attaching onUsaChoiceDisplayValue for handling choices through URL
    if (window.onUsaChoiceDisplayValue) {
      currentSurvey?.onGetChoiceDisplayValue.add((s, o) => {
        // @ts-expect-error Attaching onUsaChoiceDisplayValue for for handling choices through URL
        window.onUsaChoiceDisplayValue(s, o);
      });
    }

    // @ts-expect-error Attaching onBrazilValueChanged for handling choices through URL
    if (window.onMexicoValueChanged) {
      currentSurvey?.onValueChanged.remove(onValueChanged);
      currentSurvey?.onValueChanged.add((e, f) => {
        onValueChanged(e, f);
        // @ts-expect-error Attaching onBrazilValueChanged
        window.onMexicoValueChanged(e, f);
      });
    }
  };

  const attachPhilippinesEventListeners = (currentSurvey: Model) => {
    // @ts-expect-error Attaching onUsaChoiceDisplayValue for handling choices through URL
    if (window.onUsaChoiceDisplayValue) {
      currentSurvey?.onGetChoiceDisplayValue.add((s, o) => {
        // @ts-expect-error Attaching onUsaChoiceDisplayValue for for handling choices through URL
        window.onUsaChoiceDisplayValue(s, o);
      });
    }
    // @ts-expect-error Attaching onUsaChoicesLazyLoad for loading choices from URL
    if (window.onUsaChoicesLazyLoad) {
      currentSurvey?.onChoicesLazyLoad.add(
        // @ts-expect-error Attaching onUsaChoicesLazyLoad for for loading choices from URL
        window.onUsaChoicesLazyLoad
      );
    }
    // @ts-expect-error Attaching onPhilippinesValueChanged for handling choices through URL
    if (window.onPhilippinesValueChanged) {
      currentSurvey?.onValueChanged.remove(onValueChanged);
      currentSurvey?.onValueChanged.add((e, f) => {
        onValueChanged(e, f);
        // @ts-expect-error Attaching onPhilippinesValueChanged
        window.onPhilippinesValueChanged(e, f);
      });
    }
  };

  const attachSingaporeEventListeners = (currentSurvey: Model) => {
    // @ts-expect-error Attaching onUsaChoiceDisplayValue for handling choices through URL
    if (window.onUsaChoiceDisplayValue) {
      currentSurvey?.onGetChoiceDisplayValue.add((s, o) => {
        // @ts-expect-error Attaching onUsaChoiceDisplayValue for for handling choices through URL
        window.onUsaChoiceDisplayValue(s, o);
      });
    }

    // @ts-expect-error Attaching onAfterRenderSurveySingapore
    if (window.onAfterRenderSurveySingapore) {
      currentSurvey?.onAfterRenderSurvey.add((s, o) => {
        // @ts-expect-error Attaching onAfterRenderSurveySingapore
        window.onAfterRenderSurveySingapore(s, o);
      });
    }

    // @ts-expect-error Attaching onUsaChoicesLazyLoad for loading choices from URL
    if (window.onUsaChoicesLazyLoad) {
      currentSurvey?.onChoicesLazyLoad.add(
        // @ts-expect-error Attaching onUsaChoicesLazyLoad for for loading choices from URL
        window.onUsaChoicesLazyLoad
      );
    }
    // @ts-expect-error Attaching onPhilippinesValueChanged for handling choices through URL
    if (window.onPhilippinesValueChanged) {
      currentSurvey?.onValueChanged.remove(onValueChanged);
      currentSurvey?.onValueChanged.add((e, f) => {
        onValueChanged(e, f);
        // @ts-expect-error Attaching onPhilippinesValueChanged
        window.onPhilippinesValueChanged(e, f);
      });
    }

    // @ts-expect-error Attaching onSingaporeValueChanged for handling choices through URL
    if (window.onSingaporeValueChanged) {
      currentSurvey?.onValueChanged.remove(onValueChanged);
      currentSurvey?.onValueChanged.add((e, f) => {
        onValueChanged(e, f);
        // @ts-expect-error Attaching onPhilippinesValueChanged
        window.onSingaporeValueChanged(e, f);
      });
    }
  };

const handleFileUploads = async (_: Model, options: UploadFilesEvent) => {
  try {
    // Create a map for quick lookups later
    const files = new Map(
      options.files.map(f => {
        const newName = f.name.replaceAll(/\s|[\u00A0\u2007\u202F]/g, "_");
        const newFile = new File([f], newName, { type: f.type });
        return [newName, newFile];
      })
    );

    // Prepare the request payload
    const fileRequests = Array.from(files.values()).map(f => ({
      file_name: f.name,
      mime_type: f.type
    }));

    // Get presigned URLs
    const documents = await createPresignedUrls(clientAPIKey, {
      application_id: siaApplication.id,
      files: fileRequests
    });

    if (!documents.data.length) {
      throw new Error('No presigned URLs received');
    }

    // Create a map for quick document lookups
    const documentMap = new Map(
      documents.data.map(doc => [`${doc.file_name}-${doc.mime}`, doc])
    );

    // Upload all files in parallel
    await Promise.all(
      Array.from(files.values()).map(async (f) => {
        const doc = documentMap.get(`${f.name}-${f.type}`);
        if (doc) {
          await updateS3DocumentWithPresignedUrl(doc.download_url, f);
        }
      })
    );

    // Prepare callback data
    const callbackData = Array.from(files.values()).map((f) => {
      const doc = documentMap.get(`${f.name}-${f.type}`);
      return {
        file: f,
        content: doc?.public_url
      };
    });

    options.callback(callbackData);
  } catch (error) {
    console.error('Error in handleFileUploads:', error);
    throw error; // Re-throw to allow caller to handle the error
  }
};

  const handleClearFiles = async (_: Model, options: ClearFilesEvent) => {
    try {
      // Early return if no files to delete
      if (!options.value?.length) {
        options.callback('success');
        return;
      }
  
      // Helper function to extract path from cloudfront URL
      const extractPath = (url: string): string => {
        if (!url.startsWith(CLOUDFRONT_URL)) {
          throw new Error(`Invalid URL format: ${url}`);
        }
        return url.slice(CLOUDFRONT_URL.length + 1);
      };

      // Determine which files to delete
      const filesToDelete = options.fileName
        ? options.value.filter(item => item.name === options.fileName)
        : options.value;

      if (options.fileName && filesToDelete.length === 0) {
        throw new Error(`File with name ${options.fileName} not found`);
      }
  
      // Extract paths and delete files
      const paths = filesToDelete.map(file => extractPath(String(file.content)));
      
      const response = await deleteDocumentsFromS3(clientAPIKey, { paths });
  
      // Handle single file deletion
      if (options.fileName) {
        options.callback(response.data.errors.length ? 'error' : 'success');
        return;
      }
  
      // Handle bulk deletion
      options.callback(response.data.deleted.length ? 'success' : 'error');
    } catch (error) {
      console.error('Error in handleClearFiles:', error);
      options.callback('error');
    }
}

  const setSurveyJSON = () => {
    try {
      let surveyJSON = {
        ...applicationUtil.extraSurveyConfig(applicationConfig),
        ...surveyJson,
      };

      const clientData = JSON.parse(localStorage.getItem("clientData"));
      let prefillData = getSurveyJSONData(surveyJSON);
      if (prefillData || Boolean(isKnockoutForm)) {
        if (Boolean(isKnockoutForm)) {
          if (!prefillData) {
            prefillData = {};
          }
          // Check if the product is a drone product
          const isDroneProduct = siaApplication?.products?.includes("DRONE-ALLIANZ");
          if (isDroneProduct) {
            prefillData.allianzKnockout = true;
          } else {
            prefillData.knockout = true;
          }
        } else {
          delete prefillData.knockout;
          delete prefillData.allianzKnockout;
        }
      }

      // fix to prepopulate email in checkout form
      dispatch(setApplicantEmail(prefillData?.applicant_email ?? ""));

      surveyJSON = applicationUtil.addDynamicPanelHeaders(
        surveyJSON,
        clientData,
        prefillData,
        lang,
        isMobile
      );
      let mySurvey = new Model(surveyJSON);
      mySurvey.showNavigationButtons = false;
      mySurvey.locale = lang;
      mySurvey.onValueChanged.add(onValueChanged);
      mySurvey.setPropertyValue("showPageTitles", false);
      mySurvey.questionErrorLocation = "bottom";
      mySurvey.data = mySurvey?.data
        ? { ...mySurvey?.data, ...prefillData }
        : prefillData;
      setPrefillData(mySurvey?.data);
      mySurvey.currentPageNo = surveyPageNumber ?? 0;
      mySurvey.onTextMarkdown.add(onTextMarkdown);

      mySurvey.getAllQuestions().forEach((q) => {
        q.id = `${q.name}_`;
        if (q.getType() === "dropdown") {
          q.choices.forEach((choice) => {
            choice.elementId = `${q.name}_choice_${choice.value}`;
          });
        }
      });

      mySurvey?.onAfterRenderPanel.add((sender, options) => {
        const q = options.panel;

        q.elements.forEach((e) => {
          const question = q.getQuestionByName(e.name);
          if (question.getType() === "dropdown") {
            question.choices.forEach((choice) => {
              choice.elementId = `${e.name}_choice_${choice.value}`;
            });
          }
          question.id = `${e.name}_`;
        });
      });
      if (COUNTRY === "br") {
        attachBREventListeners(mySurvey);
      } else if (COUNTRY === "mx") {
        attachMEXICOEventListeners(mySurvey);
      } else if (COUNTRY === "us") {
        attachUSEventListeners(mySurvey);
      } else if (COUNTRY === "ph") {
        attachPhilippinesEventListeners(mySurvey);
      } else if (COUNTRY === "sg") {
        attachSingaporeEventListeners(mySurvey);
      }

      mySurvey.onUploadFiles.add(handleFileUploads)
      mySurvey.onClearFiles.add(handleClearFiles)

      setSurvey(mySurvey);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!!survey) {
      dispatch(
        setSubSteps(survey?.visiblePages?.map((p) => ({ label: p.title })))
      );
    } else {
      dispatch(setSubSteps([]));
    }
  }, [survey]);

  useEffect(() => {
    return () => {
      dispatch(setSubSteps([]));
    };
  }, []);

  const script = useRef(document.createElement("script"));

  const registerFactoryFunctions = useCallback(async () => {
    try {
      const factoryFunctions = await fetchFactoryFunctionsAPI(clientAPIKey);
      script.current.text = factoryFunctions?.data;
      document.head.appendChild(script.current);
      dispatch(setLoadingValue(false));
      dispatch(setScriptRef(script));
      localStorage.setItem("scriptLoaded", "true");
      localStorage.removeItem("scriptCallMade");
    } catch (error) {
      console.error(error);
    }
  }, []);

  const updateSurveyModelFuncs = useCallback(() => {
    if (survey) {
      dispatch(
        setSurveyModelFuncs({
          isFirstPage: () => survey?.isFirstPage,
          isLastPage: () => survey?.isLastPage,
          currentPageNo: () => survey?.currentPageNo,
          setCurrentPageNo: (pageNumber: number) => {
            survey?.setPropertyValue("currentPageNo", pageNumber);
          },
          PageCount: () => survey?.PageCount,
          prevPageFunc: () => {
            return survey?.prevPage();
          },

          nextPageFunc: () => {
            // Added custom implementation because of internal issue with surveyJS next func
            const nextPageIndex = survey?.currentPageNo + 1;
            if (nextPageIndex >= 0 && nextPageIndex < survey.visiblePageCount) {
              survey.currentPageNo = nextPageIndex;
              return true;
            }
            console.warn("Target page index is out of bounds.");
            return false;
          },

          completeLastPageFunc: () => {
            return survey?.completeLastPage();
          },
          isSurveyCompleted: () => survey?.state === "completed",
          getSurveyData: () => survey?.data,
          getConvertToISOQuestion: () => survey.getAllQuestions().find((question) => question.toJSON()?.convertToISO
          ),
          setSurveyData: (data: any) => survey?.setPropertyValue(data, data),
          checkValidState: () => survey?.validateCurrentPage(),
        })
      );
    }
  }, [survey,]);

  useEffect(() => {
    if (!scriptLoaded && !scriptCallMade) {
      localStorage.setItem("scriptCallMade", "true");
      dispatch(setLoaderText(t("FETCHING_APPLICATION")));
      dispatch(setLoadingValue(true));
      void registerFactoryFunctions();
    }
  }, []);

  useEffect(() => {
    if (
      scriptRef?.current &&
      // @ts-expect-error Attaching registerBrazilFactoryFunctions when the script is added
      window.factoryFunctions &&
      Serializer
    ) {
      if (COUNTRY === "br") {
        // @ts-expect-error Attaching registerBrazilFactoryFunctions when the script is added
        window.factoryFunctions("brazil", FunctionFactory, Serializer);
        setSurveyJSON();
      } else if (COUNTRY === "mx") {
        // @ts-expect-error Attaching registerBrazilFactoryFunctions when the script is added
        window.factoryFunctions("mexico", FunctionFactory, Serializer);
        setSurveyJSON();
      } else if (COUNTRY === "us") {
        // @ts-expect-error Attaching registerBrazilFactoryFunctions when the script is added
        window.factoryFunctions("usa", FunctionFactory, Serializer);
        setSurveyJSON();
      } else if (COUNTRY === "sg") {
        // @ts-expect-error Attaching registerBrazilFactoryFunctions when the script is added
        window.factoryFunctions("singapore", FunctionFactory, Serializer);
        setSurveyJSON();
      } else if (COUNTRY === "ph") {
        // @ts-expect-error Attaching registerBrazilFactoryFunctions when the script is added
        window.factoryFunctions("philippines", FunctionFactory, Serializer);
        setSurveyJSON();
      }
    }
  }, [scriptRef]);

  useEffect(() => {
    updateSurveyModelFuncs();
  }, [survey]);

  return survey ? <Survey model={survey} /> : null;
};

const MemoizedSurvey = React.memo(SurveyComponent, () => true);

export default MemoizedSurvey;
