import { DeleteOutline } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { Component } from "react";
import { QuestionFileModel } from "survey-core";

import "./FilePreviewComponent.css";

interface FilePreviewComponentProps {
  question: QuestionFileModel;
}

interface FilePreviewComponentState {
  question: QuestionFileModel | null;
}

class FilePreviewComponent extends Component<FilePreviewComponentProps, FilePreviewComponentState> {
  constructor(props: FilePreviewComponentProps) {
    super(props);
    this.state = {
      question: props.question,
    };
  }

  downloadFile = (fileItem: { content: string; name: string; type: string }) => {
    fetch(fileItem.content)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], fileItem.name, { type: fileItem.type });
        const reader = new FileReader();

        reader.onload = (e) => {
          const a = document.createElement("a");
          a.href = e.target?.result as string; // Type assertion for TypeScript
          a.download = fileItem.name;
          a.click();
        };

        reader.readAsDataURL(file);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  render() {
    const { question } = this.state;

    if (!question || !question.value) {
      return null;
    }

    return (
      <Box
        className="my-preview-container"
        sx={{
          display: question.value.length ? "flex" : "none",
          flexDirection: "column",
          gap: 2,
          overflowY: "auto",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          
        </Typography>
        <Box
          className="files-container"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          {question.value.map((fileItem: { content: string; name: string; type: string }) => (
            <Box
              className="file-card"
              key={fileItem.name}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                padding: 2,
                border: "1px solid #ddd",
                borderRadius: 1,
                backgroundColor: "#f9f9f9",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  flex: 1,
                }}
                title={fileItem.name}
              >
                {fileItem.name}
              </Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <IconButton
                  color="error"
                  onClick={() => question.removeFile(fileItem.name)}
                  title="Remove file"
                >
                  <DeleteOutline />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
}



export default FilePreviewComponent;
